.categories {
  .content {
    margin-top: 15rem;
  }

  .title-link {
    display: none;
  }
}

.category-section {
  border-top: 1px solid #e6e6e6;

  .subtitle {
    color: #696f68;
    font-size: 1.8rem;
    margin-top: -3rem;
    padding-bottom: 3rem;
    position: relative;
  }
}

.category-article-list {
  @include justify-content(flex-start);
  @include flexbox;
  @include flex-wrap(wrap);

  .embla-slide {
    margin-right: 2rem;
    min-width: 0;

    @include flex(0 0 70vw);
  }

  .embla-viewport {
    width: calc(100vw - 2rem);
  }

  @media screen and (min-width: 1280px) {
    padding: 0 4rem !important;
  }
}

.category-article-list2 {
  .embla-dots {
    width: calc(100vw - 12rem);

    @media screen and (min-width: 820px) {
      width: calc(100vw - 16rem);
    }

    @media screen and (min-width: 1220px) {
      width: calc(100% - 8rem);
    }
  }

  .articles-slider {
    margin-top: -5rem;

    @media screen and (max-width: 1100px) {
      margin-top: 0;
    }
  }

  .embla-container {
    margin-right: -2.5%;
    margin-left: -2.5%;
    padding: 5rem;

    @media screen and (max-width: 1100px) {
      padding: 0;
    }

    @media screen and (max-width: 820px) {
      margin-right: 0;
      margin-left: 0;
    }
  }

  .embla-slide {
    margin-right: 2.5%;
    margin-left: 2.5%;
    min-width: 0;

    &-m {
      @include flex(0 0 calc(85%/3));
    }

    &-l {
      margin-left: 0;
      margin-right: 2rem;

      @include flex(0 0 70vw);
    }

    @media screen and (min-width: 1101px) {
      &:last-child {
        margin-right: 8rem;
      }
    }
  }

  .embla-viewport {
    @media screen and (max-width: 1220px) {
      width: calc(100vw - 8rem);
    }

    @media screen and (max-width: 820px) {
      width: calc(100vw - 2rem);
    }
  }

  .category-article {
    width: 100%;
    margin-right: 0;

    @media screen and (max-width: 1100px) {
      &:hover:before {
        display: none;
      }
    }
  }
}

.category-article {
  margin-bottom: 6rem;
  margin-right: 5%;
  position: relative;
  width: 30%;

  &:nth-child(3n) {
    margin-right: 0;
  }

  @include flexbox;
  @include flex-direction(column);

  &:hover {
    &::before {
      background-color: #fff;
      box-shadow: 0 1rem 4rem 0 rgba(0, 0, 0, 0.2);
      content: '';
      display: block;
      height: calc(100% + 4rem);
      left: -5%;
      opacity: 1;
      pointer-events: none;
      position: absolute;
      top: -2rem;
      width: calc(100% + 10%);

      @include transition(opacity 0.3s);
    }
  }

  &.top:hover {
    &::before {
      content: '';
      left: -2rem;
      width: calc(100% + 4rem);
    }
  }

  .category-article-text {
    max-width: 100%;
    position: relative;
    // z-index: 4;

    h3 {
      color: $color-secondary;
    }
  }

  .category-article-img {
    background-position: center;
    background-repeat: no-repeat;
    background-size: 5rem;
    font-size: 0;
    height: 23rem;
    letter-spacing: 0;
    margin-bottom: 3rem;
    max-width: 100%;
    overflow: hidden;
    position: relative;
    width: 100%;
    word-spacing: 0;
    z-index: 50;

    a {
      height: 100%;

      @include flexbox;
      @include align-items(center);
      @include justify-content(center);

      img {
        flex-shrink: 0;
        min-height: 100%;
        min-width: 100%;
        width: auto;
        object-fit: cover;
        object-position: center center;
        will-change: transform;

        @include transition(transform 0.45s ease-in-out, opacity 0.3s linear);
        @include transform(scale(1) translateZ(0));
      }

      &:hover img {
        @include transition(transform 0.6s ease-out, opacity 0.3s !important);
        @include transform(scale(1.1) translateZ(0));
      }
    }
  }
}

.category-article-wrapper .category-article.top {
  margin-bottom: 6rem;
  width: 100%;

  @include align-items(center);
  @include flex-direction(row);
  @include justify-content(space-between);

  h3 {
    font-size: 3.2rem;
  }

  .category-article-text {
    margin-left: 4rem;
    width: 50rem;

    h3 {
      max-width: 40rem;
      color: $color-secondary;
    }

    p {
      max-width: 36rem;
      font-size: 1.8rem;
      line-height: 1.5;
    }
  }

  .category-article-img {
    height: 40rem;
    margin-bottom: 0;
    max-width: 58rem;
    width: 100%;
  }
}

.category-title {
  @include align-items(center);
  @include flexbox;
  @include justify-content(space-between);
}

.title-link {
  font-size: 1.8rem;
  font-weight: bold;
  position: relative;
  text-decoration: none;
  top: 1rem;

  @include transition(all 0.25s);

  &:after {
    background-color: $color-secondary;
    content: '';
    display: block;
    height: 0.2rem;
    position: relative;
    top: 0.3rem;
    width: 100%;

    @include transition(all 0.25s);
  }

  &:hover {
    color: $color-secondary-dark;
    text-decoration: none;

    @include animation(none);
    @include transition(all 0.25s);

    &:after {
      width: 0;

      @include transition(all 0.25s);
    }
  }

  &:focus,
  &.active,
  &:active {
    text-decoration: none;

    @include animation(none);
  }
}

@media screen and (max-width: 1280px) {
  .category-article .category-article-img {
    height: 20vw;
  }

  .category-article-wrapper .category-article.top {
    .category-article-img {
      height: 34vw;
    }
  }
}

@media screen and (max-width: 820px) {
  .category-article {
    margin-bottom: 0;
    width: 100%;

    &:hover {
      &::before {
        display: none;
      }
    }

    .category-article-img {
      height: 40vw;
    }
  }

  .category-section {
    .subtitle {
      margin-top: 0;
    }
  }

  .category-section {
    .container {
      padding-right: 0 !important;
    }
  }

  .category-title {
    margin-right: 2rem;

    h2.title,
    h2.name {
      padding-bottom: 2rem;
    }
  }
}

@media screen and (max-width: 580px) {
  .title-link {
    font-size: 1.6rem;
  }

  .category-title {

    h2.title,
    h2.name {
      padding-top: 4rem;
    }
  }
}

@media screen and (max-width: 360px) {
  .title-link {
    font-size: 1.4rem;
  }
}